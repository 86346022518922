<template>
  <b-table-simple small v-if="rules">
    <b-thead>
      <b-tr>
        <b-th>Description</b-th>
        <b-th>Share</b-th>
        <b-th>Split</b-th>
        <b-th v-if="false">Reference / Beneficiary</b-th>
        <b-th>First period</b-th>
        <b-th>Last period</b-th>
        <b-th>Group</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="rule in rules">
        <template v-for="(member, index) in rule.ReaccountRuleGroupMembers">
          <b-tr :key="rule.TransactionReaccountRuleID + member.TransactionReaccountRuleGroupMemberID"> 
            <template v-if="index === 0">
              <b-td :rowspan="rule.ReaccountRuleGroupMembers.length">
                <router-link :to="{ name: 'accounting-rule', params: { id: rule.TransactionReaccountRuleID } }">
                  {{ rule.Description }}
                </router-link>
              </b-td>
              <b-td :rowspan="rule.ReaccountRuleGroupMembers.length">{{ rule.ShareRate|percentage }}</b-td>
              <b-td :rowspan="rule.ReaccountRuleGroupMembers.length">{{ rule.SplitRate|percentage }}</b-td>
              <b-td :rowspan="rule.ReaccountRuleGroupMembers.length" v-if="false">
                <router-link :to="{ name: 'accounting-statement-spec', params: { id: rule.StatementReference } }">
                  {{ rule.StatementReference }} - 
                  {{ rule.StatementSpec.Beneficiary.FullName }}
                </router-link>
              </b-td>
            </template>
            <b-td>{{ member.FirstValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
            <b-td>{{ member.LastValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
            <b-td>
              <router-link :to="{ name: 'accounting-rule-group', 
                                  params: { id: member.RuleGroup.TransactionReaccountRuleGroupID } }">
                {{ member.RuleGroup.Description }}
              </router-link>
            </b-td>
          </b-tr>
        </template>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>

export default {
  name: 'TrxAccountingRuleList',
  components: {
  },
  props: {
    rules: Array,
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  }
}

</script>