var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rules
    ? _c(
        "b-table-simple",
        { attrs: { small: "" } },
        [
          _c(
            "b-thead",
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v("Description")]),
                  _c("b-th", [_vm._v("Share")]),
                  _c("b-th", [_vm._v("Split")]),
                  false
                    ? _c("b-th", [_vm._v("Reference / Beneficiary")])
                    : _vm._e(),
                  _c("b-th", [_vm._v("First period")]),
                  _c("b-th", [_vm._v("Last period")]),
                  _c("b-th", [_vm._v("Group")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tbody",
            [
              _vm._l(_vm.rules, function(rule) {
                return [
                  _vm._l(rule.ReaccountRuleGroupMembers, function(
                    member,
                    index
                  ) {
                    return [
                      _c(
                        "b-tr",
                        {
                          key:
                            rule.TransactionReaccountRuleID +
                            member.TransactionReaccountRuleGroupMemberID
                        },
                        [
                          index === 0
                            ? [
                                _c(
                                  "b-td",
                                  {
                                    attrs: {
                                      rowspan:
                                        rule.ReaccountRuleGroupMembers.length
                                    }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "accounting-rule",
                                            params: {
                                              id:
                                                rule.TransactionReaccountRuleID
                                            }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(rule.Description) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-td",
                                  {
                                    attrs: {
                                      rowspan:
                                        rule.ReaccountRuleGroupMembers.length
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(rule.ShareRate)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "b-td",
                                  {
                                    attrs: {
                                      rowspan:
                                        rule.ReaccountRuleGroupMembers.length
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(rule.SplitRate)
                                      )
                                    )
                                  ]
                                ),
                                false
                                  ? _c(
                                      "b-td",
                                      {
                                        attrs: {
                                          rowspan:
                                            rule.ReaccountRuleGroupMembers
                                              .length
                                        }
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name:
                                                  "accounting-statement-spec",
                                                params: {
                                                  id: rule.StatementReference
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  rule.StatementReference
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  rule.StatementSpec.Beneficiary
                                                    .FullName
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatAccountingPeriod")(
                                  member.FirstValidAccountingPeriodID
                                )
                              )
                            )
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatAccountingPeriod")(
                                  member.LastValidAccountingPeriodID
                                )
                              )
                            )
                          ]),
                          _c(
                            "b-td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "accounting-rule-group",
                                      params: {
                                        id:
                                          member.RuleGroup
                                            .TransactionReaccountRuleGroupID
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(member.RuleGroup.Description) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ]
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }